export default {
    props:
    {
        logo: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
        spinnerDisplay: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            hide : true,
            logoDisplay: this.logo,
            titleDisplay: this.title,
            subtitleDisplay: this.subtitle
        }
    },
    created: function () {
    },
    methods: {
        setHide: function (hide) {
            //this.hide = hide;
        },
        display: function (logo,title,subtitle) {
            this.logoDisplay = logo;
            this.titleDisplay = title;
            this.subtitleDisplay = subtitle;
            this.hide = false;
        }
    }
}